import { API } from "../../http";
import { NEW_ENDPOINTS } from "../../http/endpoints";
import { CURRENCY, TRANSACTION_TYPE } from "../../utils/constants";
import { message } from "antd";
import React from "react";
import { retryOperation } from "../../utils/helpers";
import { getUserInfo } from "./user";

export const getTradeInfo = (type = TRANSACTION_TYPE.BUY, { toState = true } = {}) => async (dispatch) => {
  try {
    const dataUsdt = await retryOperation(async () => {
      const { data } = await API.post(NEW_ENDPOINTS.INFO.TRADE, {
        currency: CURRENCY.USDT,
        type,
      });
      return data;
    }, 2, 1500);
    const dataEth = await retryOperation(async () => {
      const { data } = await API.post(NEW_ENDPOINTS.INFO.TRADE, {
        currency: CURRENCY.USDT,
        network: 'eth',
        type,
      });
      return data;
    }, 2, 1500);

    if (dataUsdt.data || dataEth.data) {
      const precision = 4;
      let usdtData, ethData;

      if (dataUsdt.data) {
        usdtData = {
          min_sum: +dataUsdt.data.min_sum,
          max_sum: +dataUsdt.data.max_sum,
          fee: +dataUsdt.data.fee,
          course: Math.floor(+dataUsdt.data.course * Math.pow(10, precision)) / Math.pow(10, precision),
        };
        if (toState) dispatch({ type: 'Trade/SET_USDT', payload: usdtData });
      }

      if (dataEth.data) {
        ethData = {
          min_sum: +dataEth.data.min_sum,
          max_sum: +dataEth.data.max_sum,
          fee: +dataEth.data.fee,
          course: Math.floor(+dataEth.data.course * Math.pow(10, precision)) / Math.pow(10, precision),
        };
        if (toState) dispatch({ type: 'Trade/SET_ETH', payload: ethData });
      }

      return { usdt: usdtData, eth: ethData }
    } else {
      if (dataUsdt.err === 'NO DIRECTION') throw new Error('USDT Error');
      else if (dataEth.err === 'NO DIRECTION') throw new Error('ETH Error');
      else throw new Error('Something went wrong');
    }
  } catch (e) {
    throw e;
  }
}

export const buyCrypto = (currency, sum) => async (dispatch, getState) => {
  try {
    const currentBalance = getState().user.balance_fiat ?? 0;
    const currentCurrency = currency === CURRENCY.ETH ? CURRENCY.USDT : currency;
    if (currentBalance === 0 || currentBalance < sum) throw new Error("Insufficient fiat balance");
    const { data } = await API.post(NEW_ENDPOINTS.ORDER.CREATE, {
      currency: currentCurrency,
      sum,
      type: TRANSACTION_TYPE.BUY,
      network: currency === CURRENCY.ETH ? 'eth' : undefined
    });
    if (data.NOT_ENOUGH_BALANCE) message.error('Not enough fiat balance');
    else if (data.NO_DATA) message.error('Something went wrong with API request');
    else {
      await getUserInfo();
      message.success('Your fiat account has been successfully funded');
    }
    return data;
  } catch (e) {
    if (e instanceof Error) message.error(e.message);
    else message.error("Failed to open buy order");
    throw e;
  }
}

export const sellCrypto = (currency, sum) => async (dispatch, getState) => {
  try {
    const currentBalance = currency === CURRENCY.ETH ? (getState().user.balance_usdt_eth ?? 0) : (getState().user.balance_usdt ?? 0);
    const currentCurrency = currency === CURRENCY.ETH ? CURRENCY.USDT : currency;
    if (currentBalance === 0 || currentBalance < sum) throw new Error("Insufficient cryptocurrency balance");
    const { data } = await API.post(NEW_ENDPOINTS.ORDER.CREATE, {
      currency: currentCurrency,
      sum,
      type: TRANSACTION_TYPE.SELL,
      network: currency === CURRENCY.ETH ? 'eth' : undefined
    });
    if (data.NOT_ENOUGH_BALANCE) message.error('Not enough fiat balance');
    else if (data.NO_DATA) message.error('Something went wrong with API request');
    else {
      await getUserInfo();
      message.success('Your fiat account has been successfully funded');
    }
    return data;
  } catch (e) {
    if (e instanceof Error) message.error(e.message);
    else message.error("Failed to open sell order");
    throw e;
  }
}

export const sendCrypto = (currency, sum, to_wallet) => async (dispatch, getState) => {
  try {
    const currentBalance = currency === CURRENCY.ETH ? (getState().user.balance_usdt_eth ?? 0) : (getState().user.balance_usdt ?? 0);
    const currentCurrency = currency === CURRENCY.ETH ? CURRENCY.USDT : currency;
    if (currentBalance === 0 || currentBalance < sum) throw new Error("Insufficient cryptocurrency balance");
    const { data } = await API.post(NEW_ENDPOINTS.ORDER.CREATE, {
      to_wallet,
      currency: currentCurrency,
      sum,
      type: TRANSACTION_TYPE.SEND,
      network: currency === CURRENCY.ETH ? 'eth' : undefined
    });
    message.success('Order created successfully');
    return data;
  } catch (e) {
    if (e instanceof Error) message.error(e.message);
    else message.error("Failed to open send order");
    throw e;
  }
}

export const sendFiat = (iban, sum, currency = CURRENCY.EUR) => async (dispatch, getState) => {
  try {
    const currentBalance = getState().user.balance_fiat ?? 0;
    if (currentBalance === 0 || currentBalance < sum) throw new Error("Insufficient fiat balance");
    const { data } = await API.post(NEW_ENDPOINTS.ORDER.CREATE, { iban, currency, sum, type: TRANSACTION_TYPE.SEND_FIAT });
    message.success('Order created successfully');
    return data;
  } catch (e) {
    if (e instanceof Error) message.error(e.message);
    else message.error("Failed to open send order");
    throw e;
  }
}

export const calculateFees = async (type, currency, amount, fee_use_eur = false) => {
  try {
    const { data } = await API.post(NEW_ENDPOINTS.ORDER.ESTIMATE, {
      type,
      currency: CURRENCY.USDT,
      amount,
      fee_use_eur,
      network: currency === CURRENCY.ETH ? 'eth' : 'tron'
    });
    return data;
  } catch (e) {
    console.log({ e });
    if (e instanceof Error) message.error(e.message);
    throw e;
  }
}


export const checkValidTRC = async (address) => {
  try {
    const { data } = await API.post(NEW_ENDPOINTS.INFO.CHECKTRC, {
      'network': "tron",
      address
    })
    if (!data?.valid_address) {
      message.error("TRON address is not valid!");
    }
    return data
  } catch (e) {
    console.log({ e });
    if (e instanceof Error) message.error(e.message);
    throw e;
  }
}